<template>
  <div>
    <div v-html="content"></div>
    <div class="translate-wrapper" v-show="showTranslate">
      <div class="row">
        <div class="label">参考答案：</div>
        <div>
          <div class="mb-10 col-329cf3" v-for="it in item.libList" :key="it.id">
            <span v-html="it.answerType.text"></span>
            <span v-html="it.answerType.answers.slice(0, 3).join('/')"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].answerType.translate">
        <div class="label">解析：</div>
        <div>
          <div class="mb-10" v-for="it in item.libList" :key="it.id">
            <span v-html="it.answerType.text"></span>
            <span v-html="it.answerType.translate"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].answerType.passage">
        <div class="label">参考译文：</div>
        <div v-for="it in item.libList" :key="it.id">
          <span v-html="it.answerType.text"></span>
           <span v-html="it.answerType.passage"></span>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].answerType.source">
        <div class="label">来源：</div>
        <div class="tag" v-html="item.libList[0].answerType.source"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LexicalManipulation',
  props: ['item', 'showTranslate'],
  data () {
    return {
      content: ''
    }
  },
  watch: {
    item: {
      handler () {
        let { content } = this.item
        const { list } = this.item
        if (list && list.length > 0) {
          const reg = /<image>/g
          let index = 0
          content = content.replace(reg, () => {
            const newText = `<img src="${list[index].fileUrl}" height="${list[index].height}" alt="image" />`
            index += 1
            return newText
          })
        }
        const reg2 = /<t>.*?<\/t>/g
        this.content = content.replace(reg2, (text) => {
          const pattern = /<\/?.+?\/?>/g
          const word = text.replace(pattern, '')
          const newText = `<span style="display:inline-block;width: 45px;border-bottom: 1px solid #000;text-align: center;">${word}</span>`
          return newText
        })
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
