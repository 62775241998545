<template>
  <div>
    <div class="mb-10" v-html="item.content"></div>
    <div class="mb-10" v-for="options in item.libList[0].titleType.optionsTypeList" :key="options.id" v-html="options.text"></div>
    <div class="translate-wrapper" v-show="showTranslate">
      <div class="row">
        <div class="label">参考答案：</div>
        <div class="col-329cf3">{{item.libList[0].titleType.text.replace(/\./g, '')}}-{{item.libList[item.libList.length - 1].titleType.text}}</div>
        <div class="col-329cf3" v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.answer"></span>
        </div>
      </div>
      <div class="row">
        <div class="label">解析：</div>
        <div>
          <div class="mb-10" v-for="it in item.libList" :key="it.id">
            <span v-html="it.titleType.text"></span>
            <span v-html="it.titleType.translate"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.passage">
        <div class="label">参考译文：</div>
        <div>
          <div class="mb-10" v-for="it in item.libList" :key="it.id">
            <span v-html="it.titleType.text"></span>
            <span v-html="it.titleType.passage"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.source">
        <div class="label">来源：</div>
        <div class="tag" v-html="item.libList[0].titleType.source"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadingSort',
  props: ['item', 'showTranslate']
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
