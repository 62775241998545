<template>
  <div>
    <div v-for="it in item.libList" :key="it.id">
      <div class="mb-10" v-html="common.tHandle(it.titleType.text)"></div>
    </div>
    <div class="translate-wrapper" v-show="showTranslate">
      <div class="row">
        <div class="label">参考答案：</div>
        <div class="col-329cf3" v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.text.split('.')[0]"></span><span>.</span><span v-html="it.titleType.answer"></span><span>；</span>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.translate">
        <div class="label">解析：</div>
        <div>
          <div class="mb-10" v-for="it in item.libList" :key="it.id">
            <span v-html="it.titleType.text.split('.')[0]"></span><span>.</span><span v-html="it.titleType.translate"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.passage">
        <div class="label">参考译文：</div>
        <div class="" v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.text.split('.')[0]"></span><span>.</span><span v-html="it.titleType.passage"></span>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.source">
        <div class="label">来源：</div>
        <div v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.text.split('.')[0]"></span><span>.</span><span v-html="it.titleType.source"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleFilling',
  props: ['item', 'showTranslate'],
  watch: {
    item: {
      handler () {
        const { list } = this.item
        const { libList } = this.item
        if (list && list.length > 0) {
          let lIndex = 0
          const reg = /<image>/g
          for (let i = 0, len = libList.length; i < len; i += 1) {
            let { text } = libList[i].titleType
            text = text.replace(reg, () => { // eslint-disable-line
              const newText = `<img src="${list[lIndex].fileUrl}" height="${list[lIndex].height || 'auto'}" alt="image" />`
              lIndex += 1
              return newText
            })
            libList[i].titleType.text = text
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
