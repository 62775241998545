<template>
  <div>
    <div class="" v-for="it in item.libList" :key="it.id">
      <div class="mb-10" v-html="it.titleType.text"></div>
      <div class="mb-10" v-for="options in it.titleType.optionsTypeList" :key="options.id" v-html="options.text"></div>
      <div class="translate-wrapper" v-show="showTranslate">
        <div class="row">
          <div class="label">参考答案：</div>
          <div class="col-329cf3" v-html="it.titleType.answer"></div>
        </div>
        <div class="row" v-if="it.titleType.translate">
          <div class="label">解析：</div>
          <div v-html="it.titleType.translate"></div>
        </div>
        <div class="row" v-if="it.titleType.passage">
          <div class="label">参考译文：</div>
          <div v-html="it.titleType.passage"></div>
        </div>
        <div class="row" v-if="it.titleType.source">
          <div class="label">来源：</div>
          <div class="tag" v-html="it.titleType.source"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleChoice',
  props: ['item', 'showTranslate'],
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
