<template>
  <div>
    <div v-for="it in item.libList" :key="it.id">
      <div class="mb-10" v-html="common.tHandle(it.titleType.text)"></div>
      <div class="translate-wrapper" v-show="showTranslate">
        <div class="row">
          <div class="label">参考答案：</div>
          <div class="col-329cf3" v-html="it.titleType.answer"></div>
        </div>
        <div class="row" v-if="it.titleType.translate || it.titleType.examPointList">
          <div class="label">解析:</div>
          <div v-if="it.titleType.examPointList">
            <div class="tag" v-for="point in it.titleType.examPointList" :key="point.id">{{point.labelName}}</div>
          </div>
          <div v-if="it.titleType.translate" v-html="it.titleType.translate"></div>
        </div>
        <div class="row" v-if="it.titleType.passage">
          <div class="label">参考译文：</div>
          <div v-html="item.titleType.passage"></div>
        </div>
        <div class="row" v-if="it.titleType.source">
          <div class="label">来源：</div>
          <div class="tag" v-html="it.titleType.source"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlankFilling',
  props: ['item', 'showTranslate'],
  watch: {
    item: {
      handler () {
        const { list } = this.item
        const { libList } = this.item
        if (list && list.length > 0) {
          let lIndex = 0
          const reg = /<image>/g
          for (let i = 0, len = libList.length; i < len; i += 1) {
            let { text } = libList[i].titleType
            text = text.replace(reg, () => { // eslint-disable-line
              const newText = `<img src="${list[lIndex].fileUrl}" height="${list[lIndex].height || 'auto'}" alt="image" />`
              lIndex += 1
              return newText
            })
            libList[i].titleType.text = text
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
