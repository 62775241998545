<template>
  <div>
    <div class="mb-10" v-html="content"></div>
    <div class="" v-for="it in item.libList" :key="it.id">
      <div class="mb-10" v-html="it.titleType.text"></div>
      <div class="mb-10" v-for="options in it.titleType.optionsTypeList" :key="options.id" v-html="options.text"></div>
    </div>
    <div class="translate-wrapper" v-show="showTranslate">
      <div class="row">
        <div class="label">参考答案：</div>
        <div class="col-329cf3">{{item.libList[0].titleType.text.split('.')[0]}}-{{item.libList[item.libList.length - 1].titleType.text.split('.')[0]}}.</div>
        <div class="col-329cf3" v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.answer"></span>
        </div>
      </div>
      <div class="row">
        <div class="label">解析：</div>
        <div>
          <div class="mb-10" v-for="it in item.libList" :key="it.id">
            <span v-html="it.titleType.text"></span>
            <span v-html="it.titleType.translate"></span>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.passage">
        <div class="label">参考译文：</div>
        <div class="" v-for="it in item.libList" :key="it.id">
          <span v-html="it.titleType.text.split('.')[0] + '.'"></span>
          <span v-html="it.titleType.passage"></span>
        </div>
      </div>
      <div class="row" v-if="item.libList[0].titleType.source">
        <div class="label">来源：</div>
        <div class="tag" v-html="item.libList[0].titleType.source"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadingComprehension',
  props: ['item', 'showTranslate'],
  watch: {
    item: {
      handler () {
        let { content } = this.item
        const { list } = this.item
        if (list && list.length > 0) {
          const reg = /<image>/g
          let index = 0
          content = content.replace(reg, () => {
            const newText = `<img src="${list[index].fileUrl}" height="${list[index].height}" alt="image" />`
            index += 1
            return newText
          })
        }
        const reg2 = /<t>.*?<\/t>/g
        let i = 0
        this.content = content.replace(reg2, (text) => {
          let word = text.replace(/(<t>)|(<\/t>)/g, '')
          const pattern = /<\/?.+?\/?>/g
          const arr = word.match(pattern)
          let width
          if (arr) width = arr[0].replace(/[<>]/g, '')
          word = word.replace(pattern, '')
          const newText = `<input class="input" style="width: ${width}px;max-width: 100%;" type="text" placeholder="${word}" data-index="${i}" />`
          i += 1
          return newText
        })
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/written";
</style>
